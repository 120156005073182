import React from 'react'
import { Text, Heading, Container, Stack, Divider } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import SEO from '../components/SEO/PageSEO'
import {
  Heading1,
  Heading2,
  Paragraph,
} from '../components/ContentWithStyles/TransformToChakra'

const Page = ({ location, data }) => (
  <BaseLayout>
    <SEO
      title="Contacto"
      titleSeo="Contacto"
      description="Formulario de contacto. Envíamos un mensaje. Comunícate con nosotros."
      image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
      // lang="es"
      datePublished="2021-05-11T12:29:33-03:00"
      dateModified="2021-05-11T12:29:33-03:00"
      // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
      permalink={location.pathname}
      // breadcrumbList={[]}
      // noindex={false}
    />
    <Container maxW="5xl">
      <Stack
        // textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          as="span"
        >
          <Text as="span" color="orange.400">
            Aviso
          </Text>{' '}
          legal
        </Heading>
        <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
          Términos y condiciones
        </Heading>
        <Divider />
        <Container maxW="5xl" my={10}>
          <Heading1>Formulario de contacto</Heading1>
          <Paragraph>Envíanos un mensaje.</Paragraph>

          <Heading2>Lorem ipsum dolor sit amet.</Heading2>
          <Paragraph>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius,
            incidunt.
          </Paragraph>
        </Container>
      </Stack>
    </Container>
  </BaseLayout>
)

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
